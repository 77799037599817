import { Divider, Form, Input, Modal, Tooltip } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { displayMessage } from '../../../../../helpers/messages'
import api from '../../../../../services/api'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../../../services/styles'
import { Paperclip, Plus, XCircle } from '@phosphor-icons/react'
import { AuthContext } from '../../../../../context/AuthContext/AuthContext'
let files = null
let multiple = true

export function ModalCreateMessage({ isOpen, onClose, followupReportRisk }) {
    const { showAlert, windowHeight, mobile_mode } = useContext(GeneralContext)
    const { loggedUser } = useContext(AuthContext)

    const formRef = useRef()

    const [attachmentsToSend, setAttachmentsToSend] = useState(null)

    const maxUploadFileSize = 10 * 1024 * 1024

    const [currentFiles, setCurrentFiles] = useState([])
    const fileInputRef = useRef(null)
    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onFileInputChange = event => {
        const { files } = event.target
        handleSetCurrentFile(files)
    }

    const onSubmit = async values => {
        try {
            values = {
                description: values.description,
                followup_report_risk_id: followupReportRisk?.id,
                client_id: loggedUser.id,
            }
            await sendMessage(values)
            setText(null)
        } catch (error) {
            console.log(error)
        }
    }

    const sendMessage = async (values, needDowload) => {
        try {
            const body = new FormData()
            body.append('data', JSON.stringify(values))

            if (attachmentsToSend) {
                for (let i = 0; i < attachmentsToSend.length; i++) {
                    const file = attachmentsToSend[i]
                    body.append('attachments', file)
                }
            }
            const { data } = await api.followupApprovals.sendMessage(body)
            if (needDowload) {
                const response = await api.attachments.download(data.attachment_id)
                data.content = response.data
            }
            setAttachmentsToSend([])
            onClose()
        } catch (error) {
            console.log(error)
        }
    }

    const handleSetCurrentFile = filesToSet => {
        let allClear = true,
            justImages = true
        for (let i = 0; i < filesToSet.length; i++) {
            if (!filesToSet[i].type.includes('image')) {
                justImages = false
            }
            filesToSet[i].id = Math.random() + Date.now()
            if (filesToSet[i].size > maxUploadFileSize) {
                allClear = false
                let maxSizeText = maxUploadFileSize / 1000000
                showAlert(displayMessage('MAX_ATTACHMENT_SIZE_REACHED', maxSizeText))
            }
        }
        if (allClear) {
            setCurrentFiles(current => current.concat(...filesToSet))
        }
    }

    const removeAttachment = id => {
        let files = [...currentFiles]
        files = files.filter(file => file?.id !== id)
        setCurrentFiles(files)
        if (fileInputRef?.current) {
            fileInputRef.current.value = null
        }
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            fileInputRef.current.value = null
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        if (files) {
            if (files.length || files.length == 0) {
                setCurrentFiles(files)
            } else {
                setCurrentFiles([files])
            }
        }
    }, [files])

    useEffect(() => {
        setAttachmentsToSend(currentFiles)
    }, [currentFiles])

    return (
        <Modal
            title={displayMessage('CREATE_MESSAGE')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            onCancel={() => onClose(false)}
            okText={displayMessage('SEND_MESSAGE')}
            maskClosable={false}
            bodyStyle={{
                maxHeight: 0.8 * windowHeight,
                overflowY: 'auto',
            }}
            width={455}
        >
            {
                <Form
                    onFinish={onSubmit}
                    className="max-w-[700px] w-full"
                    layout="vertical"
                    ref={formRef}
                >
                    <div className="flex flex-col gap-1">
                        <div className="">{displayMessage('DESCRIPTION')}</div>

                        <Form.Item
                            name="description"
                            rules={[{ required: true, message: displayMessage('DISPLAY_MESSAGE') }]}
                            className="mb-4"
                        >
                            <Input.TextArea
                                autoSize={{ minRows: 3 }}
                                placeholder={displayMessage('MESSAGE')}
                                className={'w-full'}
                            />
                        </Form.Item>
                    </div>

                    <div className="flex flex-wrap gap-2 bg-primary-100 p-3 items-center w-full max-h-[250px] overflow-scroll">
                        {currentFiles.map(attachment => (
                            <div
                                key={attachment.id}
                                className={`w-[175px] shadow-md hover:shadow-xl rounded bg-white ${
                                    mobile_mode ? 'w-full' : ''
                                }`}
                                style={{ border: `3px dotted ${colors.primary[200]}` }}
                            >
                                <div className="flex justify-end w-full">
                                    <Tooltip
                                        title={displayMessage('REMOVE_ATTACHMENT')}
                                        placement="top"
                                    >
                                        <XCircle
                                            size={23}
                                            className="pt-1 pr-1 cursor-pointer text-sl-red-default"
                                            onClick={() => removeAttachment(attachment.id)}
                                        />
                                    </Tooltip>
                                </div>
                                <div className="flex items-center justify-center h-[70px]">
                                    <Paperclip size={50} weight="thin" />
                                </div>
                                <Divider className="my-1" />
                                <div className="flex items-center justify-center p-2 text-xs">
                                    <Tooltip title={attachment.name} placement="top">
                                        <span className="truncate">{attachment.name}</span>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}
                        {(multiple || (!multiple && !currentFiles.length)) && (
                            <div
                                className={`flex flex-col items-center justify-center h-[140px] bg-white p-3 gap-3 rounded-lg cursor-pointer ${
                                    mobile_mode ? 'w-full' : ''
                                }`}
                                style={{ border: `3px dotted ${colors.primary[200]}` }}
                                onClick={() => onTargetClick()}
                            >
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    className="hidden"
                                    multiple={multiple}
                                    accept="all"
                                />
                                <Plus size={30} className="font-bold text-sm text-primary-900" />
                                <span className="font-bold text-sm text-primary-900 ">
                                    {displayMessage('ADD_NEW_ATTACHMENT')}
                                </span>
                            </div>
                        )}
                    </div>
                    <Divider />
                    <div className="text-xs">
                        <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                        <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                    </div>
                </Form>
            }
        </Modal>
    )
}
