import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'

export const reports_grid_columns = [
    {
        field: 'id',
        headerName: displayMessage('ID'),
        type: 'string',
        minWidth: 50,
        valueGetter: params => {
            const value = params.value
            if (!value) {
                return displayMessage('EMPTY_01')
            }
            return value
        },
        valueFormatter: params => {
            const value = params.value
            if (!value) {
                return displayMessage('EMPTY_01')
            }
            return value
        },
    },
    {
        field: 'client_name',
        headerName: displayMessage('CLIENT'),
        type: 'string',
        width: 200,
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.Address?.Client) return row.Address.Client.name
            return displayMessage('EMPTY_01')
        },
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Address?.Client) return row.Address.Client.name
            return displayMessage('EMPTY_01')
        },
    },
    // {
    //     field: 'finalization_date',
    //     headerName: displayMessage('STATUS'),
    //     type: 'string',
    //     width: 200,
    //     renderCell: params => {
    //         const inProgress = params.row.finalization_date == null
    //         return (
    //             <div
    //                 style={{
    //                     padding: 3,
    //                     borderRadius: 5,
    //                     backgroundColor: inProgress ? '#3446eb' : '#2ab556',
    //                     color: 'white',
    //                 }}
    //             >
    //                 {inProgress ? displayMessage('IN_PROGRESS') : displayMessage('FINISHED')}
    //             </div>
    //         )
    //     },
    //     valueGetter: params => {
    //         const row = params.api.getRow(params.id)
    //         const inProgress = row.finalization_date == null
    //         return inProgress ? displayMessage('IN_PROGRESS') : displayMessage('FINISHED')
    //     },
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const inProgress = row.finalization_date == null
    //         return inProgress ? displayMessage('IN_PROGRESS') : displayMessage('FINISHED')
    //     },
    // },
    {
        field: 'Address',
        headerName: displayMessage('ADDRESS'),
        type: 'string',
        width: 500,
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            const address = row.Address
            if (address)
                return `${address.city} - ${address.state} - ${address.street} - ${address.number}, ${address.neighbourhood}`
            return displayMessage('EMPTY_01')
        },
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            const address = row.Address
            if (address)
                return `${address.city} - ${address.state} - ${address.street} - ${address.number}, ${address.neighbourhood}`
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt)
                return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'updatedAt',
        headerName: displayMessage('UPDATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.updatedAt)
                return DateTime.fromISO(row.updatedAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
