import { DateTime } from 'luxon'
import { displayMessage } from '../../../../../helpers/messages'
import { List, Tooltip } from 'antd'

export default handleOnDownload => {
    return [
        {
            field: 'user_id',
            headerName: displayMessage('WHO_SEND'),
            type: 'string',
            width: 200,
            valueFormatter: params => {
                const row = params.api.getRow(params.id)
                let model = row?.client_id ? row.ClientUser : row.User
                if (row?.client_id || row?.user_id)
                    return `${model?.first_name} ${model?.last_name}`
                return displayMessage('EMPTY_01')
            },
        },
        {
            field: 'description',
            headerName: displayMessage('MESSAGE'),
            type: 'string',
            width: 500,
            valueFormatter: params => {
                const row = params.api.getRow(params.id)
                if (row?.description) return row?.description
                return displayMessage('EMPTY_01')
            },
            renderCell: params => {
                return (
                    <Tooltip
                        placement="bottom"
                        title={params.value}
                        overlayStyle={{ maxWidth: 500 }}
                        overlayInnerStyle={{
                            backgroundColor: 'white',
                            color: 'black',
                            textAlign: 'justify',
                        }}
                    >
                        <strong style={{ width: '100%', fontWeight: 'normal' }}>
                            {params.value}
                        </strong>
                    </Tooltip>
                )
            },
        },
        {
            field: 'attachments',
            headerName: displayMessage('ATTACHMENTS'),
            type: 'string',
            width: 450,
            renderCell: params => {
                const renderList = dataSource => {
                    return (
                        <>
                            <List
                                size="small"
                                className="rounded p-0 m-0"
                                itemLayout="horizontal"
                                dataSource={dataSource}
                                renderItem={item => (
                                    <List.Item className="p-2">
                                        <a
                                            key={item.id}
                                            download
                                            style={{ display: 'block', marginBottom: '4px' }}
                                            onClick={() => handleOnDownload(item)}
                                        >
                                            {item.name}
                                        </a>
                                    </List.Item>
                                )}
                            />
                        </>
                    )
                }
                const row = params.api.getRow(params.id)
                if (row?.Attachments.length > 0) {
                    return (
                        <Tooltip
                            title={renderList(row.Attachments)}
                            overlayStyle={{ maxWidth: 500 }}
                            overlayInnerStyle={{
                                backgroundColor: 'white',
                                color: 'black',
                                textAlign: 'justify',
                            }}
                        >
                            {row.Attachments.map((attachment, index) => {
                                return (
                                    <div className="flex flex-row">
                                        <a
                                            key={attachment.id}
                                            download
                                            style={{ display: 'block', marginBottom: '4px' }}
                                            onClick={() => handleOnDownload(attachment)}
                                        >
                                            {attachment.name}
                                        </a>
                                    </div>
                                )
                            })}
                        </Tooltip>
                    )
                }
                return displayMessage('EMPTY_01')
            },
        },
        {
            field: 'createdAt',
            headerName: displayMessage('SEND_DATE'),
            type: 'date',
            width: 200,
            valueFormatter: params => {
                const row = params.api.getRow(params.id)
                if (row.createdAt)
                    return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
                return displayMessage('EMPTY_01')
            },
        },
    ]
}
